import http from '../../../../../libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class ActuacionesProcesosJudicialesService {
    get (params) {
        return http.get(`${baseUrl}/actuaciones/paginate`, {
            params,
            headers: {
                loading: false
            }
        })
    }

    getTypes () {
        return http.get(`${baseUrl}/actuaciones/paginate-types`, {
            headers: {
                loading: false
            }
        })
    }

    save (payload) {
        return http.post(`${baseUrl}/actuaciones`, payload, {
            headers: {
                loading: false
            }
        })
    }

    update (id, payload) {
        return http.put(`${baseUrl}/actuaciones/${id}`, payload, {
            headers: {
                loading: false
            }
        })
    }

    find (id) {
        return http.get(`${baseUrl}/actuaciones/${id}`, {
            headers: {
                loading: false
            }
        })
    }
}
