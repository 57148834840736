import http from '../../../../../libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class ProcesosJudicialesService {
    getSearch (params) {
        return http.get(`${baseUrl}/procesos-judiciales/paginate`, {
            params,
            headers: {
                loading: false
            }
        })
    }

    find (id) {
        return http.get(`${baseUrl}/procesos-judiciales/${id}`, {
            headers: {
                loading: false
            }
        })
    }

    save (payload) {
        return http.post(`${baseUrl}/procesos-judiciales`, payload, {
            headers: {
                loading: true
            }
        })
    }

    saveDemandanteDemandado (payload) {
        return http.post(`${baseUrl}/procesos-judiciales/add-demandante-demandado`, payload, {
            headers: {
                loading: true
            }
        })
    }

    getClasesProcesos () {
        return http.get(`${baseUrl}/clases-procesos-judiciales/all`, {
            headers: {
                loading: false
            }
        })
    }
}
