import { createStore } from 'vuex'
import ProcesosJudicialesService from '../services/procesosJudiciales.service'
import ActuacionesService from '../services/actuaciones.service'

const _ProcesosJudicialesService = new ProcesosJudicialesService()
const _ActuacionesService = new ActuacionesService()
const ProcesosJudicialesStore = {
  state: {
    listProcesosJudiciales: [],
    newProcesoJudicial: {
      accionanteId: '',
      accionante: {
        numeroIdentificacion: '',
        fullname: '',
        correo: '',
        telefono: '',
        departamentoId: '',
        direccion: '',
        docTipoId: '',
        municipioId: '',
        representanteLegal: '',
        representanteLegalContacto: ''
      },
      accionadoId: '',
      accionado: {
        numeroIdentificacion: '',
        fullname: '',
        correo: '',
        telefono: '',
        departamentoId: '',
        direccion: '',
        docTipoId: '',
        municipioId: '',
        representanteLegal: '',
        representanteLegalContacto: ''
      },
      juzgadoId: '',
      juzgado: {
        nombre: '',
        categoriaId: '',
        code: '',
        departamentoId: '',
        municipioId: ''
      },
      claseProcesoId: '',
      descripcionMedidaCautelar: '',
      fechaNotificacion: '',
      fechaReparto: '',
      medidaCautelar: '',
      numeroRadicado: '',
      observaciones: '',
      responsableId: '',
      abogadoExterno: ''
    },
    procesoJudicial: {
      agendaAssociated: [],
      accionanteId: '',
      accionantesProcesosJudiciales: [
        {
          accionante: {
            numeroIdentificacion: '',
            fullname: '',
            docTipoId: '',
            municipio: {

            }
          }
          // correo: '',
          // telefono: '',
          // departamentoId: '',
          // direccion: '',
          // municipioId: '',
          // representanteLegal: '',
          // representanteLegalContacto: ''
        }
      ],
      accionadoId: '',
      accionadosProcesosJudiciales: [
        {
          accionado: {
            numeroIdentificacion: '',
            fullname: '',
            docTipoId: '',
            municipio: {

            }
          }
          // correo: '',
          // telefono: '',
          // departamentoId: '',
          // direccion: '',
          // municipioId: '',
          // representanteLegal: '',
          // representanteLegalContacto: ''
        }
      ],
      juzgadoId: '',
      juzgadoProcesosJudiciales: {
        nombre: '',
        categoria: {},
        categoriaId: '',
        code: '',
        departamentoId: '',
        municipioId: '',
        localizacion: ''
      },
      claseProcesoId: '',
      descripcionMedidaCautelar: '',
      fechaNotificacion: '',
      fechaReparto: '',
      medidaCautelar: '',
      numeroRadicado: '',
      observaciones: '',
      responsableId: '',
      actuacionesAssociated: [
        {
          anotacion: ''
        }
      ],
      paths: [],
      abogadoExterno: ''
    },
    listClasesProcesosJudiciales: [],
    listActuaciones: {
      rows: [],
      count: 0
    },
    listActuacionesTipos: []
  },
  getters: {
    getListProcesosJudiciales: (state) => state.listProcesosJudiciales,
    getNewProcesoJudicial: (state) => state.newProcesoJudicial,
    getListClasesProcesosJudiciales: (state) => state.listClasesProcesosJudiciales,
    getProcesoJudicial: (state) => state.procesoJudicial,
    getListActuaciones: (state) => state.listActuaciones,
    getListActuacionesTipos: (state) => state.listActuacionesTipos
  },
  mutations: {
    setNewProcesoJudicialAccionado (state, payload) {
      state.newProcesoJudicial.accionadoId = payload.accionadoId
      state.newProcesoJudicial.accionado = payload.accionado
    },
    setNewProcesoJudicialAccionante (state, payload) {
      state.newProcesoJudicial.accionante = payload.accionante
      state.newProcesoJudicial.accionanteId = payload.accionanteId
    },
    setNewProcesoJudicialJuzgado (state, payload) {
      state.newProcesoJudicial.juzgadoId = payload.juzgadoId
      state.newProcesoJudicial.juzgado = payload.juzgado
    },
    setNewProcesoJudicial (state, payload) {
      state.newProcesoJudicial.claseProcesoId = payload.claseProcesoId
      state.newProcesoJudicial.descripcionMedidaCautelar = payload.descripcionMedidaCautelar
      state.newProcesoJudicial.fechaNotificacion = payload.fechaNotificacion
      state.newProcesoJudicial.fechaReparto = payload.fechaReparto
      state.newProcesoJudicial.medidaCautelar = payload.medidaCautelar
      state.newProcesoJudicial.numeroRadicado = payload.numeroRadicado
      state.newProcesoJudicial.observaciones = payload.observaciones
      state.newProcesoJudicial.responsableId = payload.responsableId
      state.newProcesoJudicial.abogadoExterno = payload.abogadoExterno
    },
    setProcesoJudicialAccionado (state, payload) {
      state.newProcesoJudicial.accionadoId = payload.accionadoId
      state.newProcesoJudicial.accionado = payload.accionado
    },
    setProcesoJudicialAccionante (state, payload) {
      state.newProcesoJudicial.accionante = payload.accionante
      state.newProcesoJudicial.accionanteId = payload.accionanteId
    },
    setProcesoJudicialJuzgado (state, payload) {
      state.newProcesoJudicial.juzgadoId = payload.juzgadoId
      state.newProcesoJudicial.juzgado = payload.juzgado
    },
    setProcesoJudicial (state, payload) {
      state.newProcesoJudicial.claseProcesoId = payload.claseProcesoId
      state.newProcesoJudicial.descripcionMedidaCautelar = payload.descripcionMedidaCautelar
      state.newProcesoJudicial.fechaNotificacion = payload.fechaNotificacion
      state.newProcesoJudicial.fechaReparto = payload.fechaReparto
      state.newProcesoJudicial.medidaCautelar = payload.medidaCautelar
      state.newProcesoJudicial.numeroRadicado = payload.numeroRadicado
      state.newProcesoJudicial.observaciones = payload.observaciones
      state.newProcesoJudicial.responsableId = payload.responsableId
    },
    setListProcesosJudiciales (state, payload) {
      state.listProcesosJudiciales = payload
    },
    setListClasesProcesosJudiciales (state, payload) {
      state.listClasesProcesosJudiciales = payload
    },
    setProcesoJudicialFind (state, payload) {
      state.procesoJudicial = payload
    },
    setActuaciones (state, payload) {
      state.listActuaciones = payload
    },
    setActuacionesTipos (state, payload) {
      state.listActuacionesTipos = payload
    }
  },
  actions: {
    storeGetSearch ({ commit }, params = {}) {
      return _ProcesosJudicialesService.getSearch(params).then(({ data }) => {
        commit('setListProcesosJudiciales', data)
        return data
      })
    },
    guardarProcesoJudicial ({ commit }, payload = {}) {
      return _ProcesosJudicialesService.save(payload).then((response) => {
        return response
      })
    },
    saveDemandanteDemandado ({ commit }, payload = {}) {
      return _ProcesosJudicialesService.saveDemandanteDemandado(payload).then(({ data }) => {
        return data
      })
    },
    setNewProcesoJudicialAccionado ({ commit }, payload = {}) {
      commit('setNewProcesoJudicialAccionado', payload)
    },
    setNewProcesoJudicialAccionante ({ commit }, payload = {}) {
      commit('setNewProcesoJudicialAccionante', payload)
    },
    setNewProcesoJudicialJuzgado ({ commit }, payload = {}) {
      return commit('setNewProcesoJudicialJuzgado', payload)
    },
    setNewProcesoJudicial ({ commit }, payload = {}) {
      commit('setNewProcesoJudicial', payload)
    },
    setProcesoJudicialAccionado ({ commit }, payload = {}) {
      commit('setNewProcesoJudicialAccionado', payload)
    },
    setProcesoJudicialAccionante ({ commit }, payload = {}) {
      commit('setNewProcesoJudicialAccionante', payload)
    },
    setProcesoJudicialJuzgado ({ commit }, payload = {}) {
      return commit('setNewProcesoJudicialJuzgado', payload)
    },
    setProcesoJudicial ({ commit }, payload = {}) {
      commit('setNewProcesoJudicial', payload)
    },
    actionListClasesProcesosJudiciales ({ commit }) {
      return _ProcesosJudicialesService.getClasesProcesos().then(({ data }) => {
        commit('setListClasesProcesosJudiciales', data)
        return data
      })
    },
    actionProcesoJudicial ({ commit }, id) {
      return _ProcesosJudicialesService.find(id).then(({ data }) => {
        commit('setProcesoJudicialFind', data)
        return data
      })
    },
    actionActuaciones ({ commit }, payload = {}) {
      return _ActuacionesService.get(payload).then(({ data }) => {
        commit('setActuaciones', data)
        return data
      })
    },
    actionActuacionesTipos ({ commit }) {
      return _ActuacionesService.getTypes().then(({ data }) => {
        commit('setActuacionesTipos', data)
        return data
      })
    },
    actionSaveActuacion ({ commit }, payload = {}) {
      return _ActuacionesService.save(payload).then(({ data }) => {
        return data
      })
    },
    actionUpdateActuacion ({ commit }, { id, payload = {} }) {
      return _ActuacionesService.update(id, payload).then(({ data }) => {
        return data
      })
    },
    actionFindActuacion ({ commit }, id) {
      return _ActuacionesService.find(id).then(({ data }) => {
        return data
      })
    }
  }
}
const store = createStore(ProcesosJudicialesStore)
export default {
  namespaced: true,
  ...store
}
